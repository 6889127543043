
import ApplicationFormPreview from "./applicationFormPreview/applicationFormPreview.component";

const ApplicationForm = () => {
    return(
        <div>
            <ApplicationFormPreview/>
        </div>
    )
};
export default ApplicationForm;