
import ContactDetailsPreview from "./footerPreview/footerPreview.component";

const Footer = () => {
    return (
        <div>
            <ContactDetailsPreview/>
        </div>
    )
};
export default Footer;